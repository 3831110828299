import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { Textarea } from '@meetup/swarm-components';
import { Icon } from '@meetup/swarm-components';
import Example from '../../components/examples/textarea';
import PropsTable from '../../components/propstable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Textarea`}</h1>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Textarea placeholder='textarea component for v2' onChange={() => null} />
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Textarea value='textarea component for v2' onChange={() => null}/>
`}</code></pre>
    <h2>{`Example`}</h2>
    <Example mdxType="Example" />
    <h3>{`Props`}</h3>
    <hr></hr>
    <br />
    <PropsTable propMetaData={Textarea.__docgenInfo} mdxType="PropsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      